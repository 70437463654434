:root {
  --color-primary-0: #4682b4;
  --color-primary-1: #9cc3e4;
  --color-primary-2: #6aa0cc;
  --color-primary-3: #2b6ba0;
  --color-primary-4: #12538a;
  --color-complement-0: #ffb958;
  --color-complement-1: #ffdaa8;
  --color-complement-2: #ffc87c;
  --color-complement-3: #f9a635;
  --color-complement-4: #d5820f;
  --header-bg-url: url('/assets/_sn_/bg/Bullseye-Gradient.svg');
}
